class RegisterModule extends HTMLElement {
  constructor () {
    super ()

    this.form = this.querySelector('form')
    this.emailField = this.form?.querySelector('.js-email')
    this.emailConfirmationField = this.form?.querySelector('.js-email-confirmation')
    this.passwordField = this.form?.querySelector('.js-password')
    this.passwordConfirmationField = this.form?.querySelector('.js-password-confirmation')
    this.buttonSubmit = this.form?.querySelector('button')
    this.error = false;

    this.buttonSubmit && this.buttonSubmit.addEventListener('click', (e) => {
      e.preventDefault()
      this.clearError()

      if (!this.emailField && !this.passwordField) {
        return
      }

      if (this.emailField.value == '') {
        this.emailField.classList.add('input--error')
        this.error = true
      } else if (this.emailField.value != this.emailConfirmationField.value) {
        this.emailConfirmationField.classList.add('input--error')
        this.error = true
      }

      if (this.passwordField.value == '') {
        this.passwordField.classList.add('input--error')
        this.error = true
      } else if (this.passwordField.value != this.passwordConfirmationField.value) {
        this.passwordConfirmationField.classList.add('input--error')
        this.error = true
      }

      if (!this.error) {
        this.form.submit()
      }
    })
  }

  clearError () {
    const inputEls = this.form?.querySelectorAll('input')
    this.error = false

    inputEls && inputEls.length && inputEls.forEach(input => {
      input && input.classList.remove('input--error')
    })
  }
}

window.customElements.define('register-module', RegisterModule)
